import React, { Component } from 'react'
import ManyBox from './ManyBox'
import zu127 from '@assets/组127.png'
import zu128 from '@assets/组128.png'
import zu129 from '@assets/组129.png'
import zu130 from '@assets/组130.png'
import './index.scss'

export default class ManyM extends Component {
    state = {
        Many:
            [{
                marginRight:'10',
                img: zu127,
                title: '数据可视化',
                p: '建立园区管理平台，集合多元数据，打通规划、建设、运营等多场景。',
            },
            {
                img: zu128,
                title: '虚拟仿真',
                p: '以高度逼真的视觉效果与数据化场景操控，给予用户沉浸式体验。',
            },
            {
                marginRight:'10',
                img: zu129,
                title: 'AR、VR',
                p: '您提供行业需求、我们提供技术策划、共同打造AR新营销新创意。',
            },
            {
                img: zu130,
                title: '其他方案',
                p: '为实现全域旅游构建智慧景区提供全方位服务,实现可视化管理。',
            },]
    
    }
    render() {
        return (
            <div className="ManyM">
                <h1 style={{marginBottom:'30px'}}>多场景解决方案</h1>
                <div className="manymbox">
                    <ManyBox Many={this.state.Many}/>
                </div>
            </div>
        )
    }
}
