import React, { Component } from 'react'
import ldwn from '@assets/办事大厅概况手机.png'
import smh from '@assets/四明湖手机.png'
import czyq from '@assets/承载园区手机.png'
import nxgz from '@assets/南浔古镇手机.png'
import CaseM from './CasesM'
import './index.scss'

export default class CaseshowsM extends Component {
    state = {
        case: [
            {
                img: ldwn,
                title: '中国计量大学——量大微脑',
                li1: '打通校园数据通道使整个平台具有活力',
                pathm:'/LargegamountofmicrobrainM'
            },
            {
                img: smh,
                title: '四明湖水库智慧水利',
                li1: '轻松应对各类人员的数据需求，对应不同数据权限',
                pathm:'/FoursiminghuM'
            },
            {
                img: czyq,
                title: '智慧园区安防综管平台',
                li1: '园区综合安防展示的可视化决策辅助平台',
                pathm:'/WisdomparkM'
            },
            {
                img: nxgz,
                title: '古镇互动沙盘',
                li1: '南浔古镇现状及未来规划蓝图',
                pathm:'/NanxuntownM'
            },
        ]
    }
    render() {
        return (
            <div>
                <div className="caseshowsmback">
                </div>
                <h1 className="caseshowsmbacktitle">案例展示</h1>
                <div className="caseshowsmbody">
                    <CaseM casem={this.state.case}/>
                </div>
            </div>
        )
    }
}
