import React, { Component } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper';
import './index.scss'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Autoplay, Pagination, Navigation]);
export default class Shuffling extends Component {
    render() {
        return (
            <div className="shuffling">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay={{
                        "delay": 3000, //自动滚屏速度
                        "disableOnInteraction": false  //false: 鼠标操作屏幕后继续自动滚屏
                    }}
                    style={{ width: '100%' }}
                    loop
                >
                    <SwiperSlide>
                        <div className="shbox">
                            {/* <Player
                                playsInline
                                aspectRatio='auto'
                                muted={true}
                                autoPlay
                                // poster='@assets/logoblue.png'
                                src={mp4}
                                type="video/mp4"
                                style={{ width: '100%' }}
                                object-fit='fill'
                                loop
                            >
                                <BigPlayButton position="center" disabled />
                                <ControlBar autoHide={false} disableCompletely={true} />
                            </Player> */}

                            <img src="http://www.xuanyantech.com/assets/手机视频.gif" alt="" style={{ width: '100%' }} />
                            <div className="shufflingboxh1">
                                <h1>炫眼科技领航</h1>
                                <p>一家以计算机图形学为基础的中国原创的科技公
                                    <br />司，以产品研发、生产、销售、定制服务为一体
                                    <br />的国家高新技术企业</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide><div className="shbox"><img src={require('@assets/手机轮播1.png').default} alt="" style={{ width: '100%' }} /></div></SwiperSlide>
                    <SwiperSlide><div className="shbox"><img src={require('@assets/手机轮播2.png').default} alt="" style={{ width: '100%' }} /></div></SwiperSlide>
                    {/* <SwiperSlide><img src="http://www.xuanyantech.com/assets/测试.mp4" alt="" style={{ width: '100%' }} /></SwiperSlide> */}
                </Swiper>
            </div >
        );
    }
}
