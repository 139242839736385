import React, { Component } from 'react'
import { Button } from 'antd';
// import solutionimg from '@assets/南浔古镇.png'
import { Link } from 'react-router-dom';
import './index.css'

export default class Solution1 extends Component {
    state = {
        p: '全面体现南浔古镇现状及未来规划蓝图，助力参观者景区信息导览，结合电子大屏与实体沙盘科技化的展示景区目前规划的理念与未来蓝图、三维导览、多元化介绍、多种待机模式助力游客游玩体验，通过多样数字化素诠释古镇底蕴。'
    }
    render() {
        let userMessage;
        if (this.props.loggedIn) {
            userMessage = (
                ''
            )
        } else {
            userMessage = (
                <Link to='/Nanxuntown'>
                    <Button ghost className="solutionbutton">立即查看</Button>
                </Link>
            )
        }
        return (
            <div>
                <div className="solutioncasetext">
                    <div className="solutioncasetextp">
                        <p>
                            {this.state.p}
                        </p>
                    </div>
                    {userMessage}
                </div>
                <div className="solutionimgdivs">
                    <div className="solutionimgdiv">
                        <img src="/assets/南浔古镇.png" alt="" className="solutionimg"  />
                    </div>
                </div>
            </div>

        )
    }
}
