import React, { Component } from 'react'
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import './index.css'

export default class Square2 extends Component {
    state = this.props.Squareg
    render() {
        let button;
        if (this.state.done) {
            button = (
                <Link to={this.state.luyou}>
                    <Button type="primary" className="showsbutton">查看详情</Button>
                </Link>
            )
        } else {
            button = ('')
        }
        return (
            <div className="Square">
                <div className="Squaretextg">
                    <h1>{this.state.h1}</h1>
                    <p>{this.state.p}</p>
                    {button}
                </div>
                <img src='/assets/案例小标.png' alt="" className="Squareimgsmalls" />
                <div className="Squareimg">
                    <img src={this.state.img} alt="" />
                </div>
            </div>
        )
    }
}