import React, { Component } from 'react'
import { HashRouter } from 'react-router-dom';
import { BackTop } from 'antd';
import BasisMobile from './Mobile/BasisMobile'
import './App.css'

import Basis from './component/Basis'

export default class App extends Component {
    componentWillUnmount() {
        this.setState = () => false;
    }
    render() {
        let Web;
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
            Web = (
                <div>
                    <HashRouter>
                        <div className="mmin">
                            <BasisMobile />
                        </div>
                    </HashRouter>
                    <BackTop visibilityHeight='-10' style={{ marginBottom: '0px',marginRight:'30px'}} >
                        <img src='https://www.xuanyantech.com/assets/top.png' alt="" style={{width:'65px'}}/>
                    </BackTop>
                </div>
            )
        } else {
            Web = (
                <div>
                    <HashRouter>
                        <div className="min">
                            {/* <Route path='/' component={Basis} /> */}
                            <Basis />
                        </div>
                    </HashRouter>
                    <BackTop style={{ marginBottom: '50px' }}>
                        <img src={require('@assets/小火箭.png').default} alt="" />
                    </BackTop>
                </div>
            )
        }
        return (
            <div>
                {Web}
            </div>
        )
    }
}
