import React, { Component } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import './index.scss'

export default class Solutionswiper extends Component {
    state ={
        Solutionswiper1:{
            title:'智慧园区',
            p:'助力园区构建一个统一的数据服务平台，实现高效、便捷的集中式管理'
        },
        Solutionswiper2:{
            title:'智慧景区',
            p:'三维导览、多元化介绍、多种待机模式助力游客游玩体验'
        },
        Solutionswiper3:{
            title:'智慧湖泊',
            p:'为环境监管的数据联动、应急事故的指挥调度等提供科学的依据'
        },
        Solutionswiper4:{
            title:'智慧社区',
            p:'为新型的社区提供一个直观、简化的平台，让工作者人人可用、人人会用、人人好用'
        },
        Solutionswiper5:{
            title:'智慧高校',
            p:'深层次整合挖掘校园信息化数据，有效的支持和辅助校园管理'
        },
        Solutionswiper6:{
            title:'智慧场管',
            p:'从区域——建筑——楼层——房间——物体，实现精细的可视化孪生管理'
        },
        Solutionswiper7:{
            title:'智慧工厂',
            p:'利用物联网的技术和设备监控技术，实现生产线运行状态可视化、设备设施可视化'
        },
        Solutionswiper8:{
            title:'智慧工地',
            p:'运用信息化手段，通过三维设计平台，实现工程施工可视化智能管理'
        },
        Solutionswiper9:{
            title:'智慧医院',
            p:'全面整合有线、无线、运维、安全等产品，通过更高效、智能的解决方案助推“智慧医院”'
        },
    }
    render() {
        return (
            <div className="qqq">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    style={{ width: '100%' }}
                    pagination={{ clickable: true }}
                >
                    <SwiperSlide>
                        <div className="solutionswiper">
                            <div className="solutionswiperimg"><img src={require('@assets/汽车.png').default} alt="" /></div>
                            <div className="solutionswipertxt">
                                <h3>{this.state.Solutionswiper1.title}</h3>
                                <p>{this.state.Solutionswiper1.p}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="solutionswiper">
                            <div className="solutionswiperimg"><img src={require('@assets/高校.png').default} alt="" /></div>
                            <div className="solutionswipertxt">
                            <h3>{this.state.Solutionswiper2.title}</h3>
                                <p>{this.state.Solutionswiper2.p}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="solutionswiper">
                            <div className="solutionswiperimg"><img src={require('@assets/安防.png').default} alt="" /></div>
                            <div className="solutionswipertxt">
                            <h3>{this.state.Solutionswiper3.title}</h3>
                                <p>{this.state.Solutionswiper3.p}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="solutionswiper">
                            <div className="solutionswiperimg"><img src={require('@assets/景区.png').default} alt="" /></div>
                            <div className="solutionswipertxt">
                            <h3>{this.state.Solutionswiper4.title}</h3>
                                <p>{this.state.Solutionswiper4.p}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="solutionswiper">
                            <div className="solutionswiperimg"><img src={require('@assets/汽车.png').default} alt="" /></div>
                            <div className="solutionswipertxt">
                            <h3>{this.state.Solutionswiper5.title}</h3>
                                <p>{this.state.Solutionswiper5.p}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="solutionswiper">
                            <div className="solutionswiperimg"><img src={require('@assets/高校.png').default} alt="" /></div>
                            <div className="solutionswipertxt">
                            <h3>{this.state.Solutionswiper6.title}</h3>
                                <p>{this.state.Solutionswiper6.p}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="solutionswiper">
                            <div className="solutionswiperimg"><img src={require('@assets/安防.png').default} alt="" /></div>
                            <div className="solutionswipertxt">
                            <h3>{this.state.Solutionswiper7.title}</h3>
                                <p>{this.state.Solutionswiper7.p}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="solutionswiper">
                            <div className="solutionswiperimg"><img src={require('@assets/景区.png').default} alt="" /></div>
                            <div className="solutionswipertxt">
                            <h3>{this.state.Solutionswiper8.title}</h3>
                                <p>{this.state.Solutionswiper8.p}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="solutionswiper">
                            <div className="solutionswiperimg"><img src={require('@assets/汽车.png').default} alt="" /></div>
                            <div className="solutionswipertxt">
                            <h3>{this.state.Solutionswiper9.title}</h3>
                                <p>{this.state.Solutionswiper9.p}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>  
            </div>
        )
    }
}
