import React, { Component } from 'react'


export default class NanxuntownM extends Component {
    render() {
        return (
            <div className="largegmbody">
                <div className="largegmtop">
                    <div className="backm" onClick={() => this.props.history.goBack()}>
                    <img src={require('@assets/路径.png').default} alt="" />
                        <span>返回</span>
                    </div>
                </div>
                <h1>南浔古镇数字沙盘</h1>
                <h4>助力园区高标准建设、高质量管理、高效化运营</h4>
                <p>
                全面体现南浔古镇现状及未来规划蓝图，助力参观者景区信息导览，结合电子大屏与实体沙盘科技化的展示景区目前规划的理念与未来蓝图、三维导览、多元化介绍、多种待机模式助力游客游玩体验，通过多样数字化素诠释古镇底蕴。</p>
                <p> 一个控制核心三个展示平台：智能沙盘、巨幕大屏、文创互动、软硬件平台打通、实体沙盘互动、声光平台控制。</p>
                <img src='/assets/南浔古镇.png' alt="" />
            </div>
        )
    }
}
