import React, { Component } from 'react'
// import Enterprisecultureimg from '../../assets/企业文化.png'
// import honor from '../../assets/企业荣誉.png'
// import GG from '../../assets/国高.png'
// import HG from '../../assets/杭高.png'
// import CY from '../../assets/雏鹰.png'
// import A1 from '@assets/4.jpg'
// import A2 from '@assets/3.jpg'
// import A3 from '@assets/5.jpg'
// import A4 from '@assets/2.jpg'
// import A5 from '@assets/6.jpg'
// import A6 from '@assets/1.jpg'
import Culture from './Culture'
import Itemimg from './Itemimg'
import Enterprise from './Enterprise'
import PubSub from 'pubsub-js'
import './index.css'

export default class About extends Component {
    state = {
        abouttitle: '炫眼科技',
        Companyprofilep: '炫眼科技数字孪生技术及平台已在近100家政府企业单位和多座城市落地应用，覆盖智慧高校、社区园区、景区、湖泊、医院、工地、工厂等多个行业领域。其中，代表案例包括中国计量大学智慧校园、金华柳湖智慧社区、电力公司园区、四明湖湖泊治理、富阳多家智安医院、南浔古镇智慧景区、新昌智慧工地、杭州师范大学图书馆等数字孪生应用。',
        arr: [
            { aboutname: '阿里巴巴' },
            { aboutname: '中国移动' },
            { aboutname: '华数传媒' },
            { aboutname: '中国电信' },
            { aboutname: '商汤科技' },
            { aboutname: '浙大中控' },
            { aboutname: '腾讯云平台' },
            { aboutname: '吉利汽车' },
            { aboutname: '杭州亚组委' },
            { aboutname: '国家电网' },
            { aboutname: '诸暨文旅' },
            { aboutname: '高德地图' },
            { aboutname: '中国计量大学' },
            { aboutname: '杭州师范大学' },
            { aboutname: '中国民航大学' },
        ],
        itemimg: [
            { imgs: '/assets/6.jpg' },
            { imgs: '/assets/4.jpg' },
            { imgs: '/assets/2.jpg' },
            { imgs: '/assets/3.jpg' },
            { imgs: '/assets/1.jpg' },
            { imgs: '/assets/5.jpg' },
        ],
        email: '510989847@qq.com',
        address: '浙江省杭州市钱塘新区文海南路海聚中心6栋',
        phone: '157-5717-6813 （张总）微信同号',
        name: 'Companyprofile'
    }
    componentDidMount() {
        this.address()
    }
    address=()=>{
        PubSub.publish('address',{
            address:this.props.location.pathname
        });
    }
    render() {
        return (
            <div>
                <div className="abouttopmao" id="top" name="top"></div>
                <div className="aboutheader">
                    <div className="aboutimgs"></div>
                    <h1>{this.state.abouttitle}</h1>
                    <p>做 中 国 卓 越 的 智 能 数 据 产 品 和 服 务 提 供 商</p>
                </div>
                <div className="aboutbody">
                    {/* 公司简介 */}
                    <div className="Companyprofile" name="Companyprofile" id="Companyprofile">
                        <div className="headings"><h2>公司简介</h2></div>
                        <div className="Companyprofileleft">
                            <h1>杭州炫眼信息科技有限公司</h1>
                            <div className="Companyprofilelefth2">
                                <h2>一家以计算机图形学为基础的中国原创科技公司</h2>
                            </div>
                        </div>
                        <div className="Companyprofileright">
                            <p>{this.state.Companyprofilep}</p>
                        </div>
                    </div>
                    {/* 企业文化 */}
                    <div className="enterpriseculture">
                        <div className="headings"><h2>企业文化</h2></div>
                        <div className="enterpriseculturebody">
                            <img src='/assets/企业文化.png' alt="" style={{ height: 500 }} />
                            <div className="enterprisecultureright">
                                <Culture />
                            </div>
                        </div>
                    </div>
                    {/* 合作企业 */}
                    <div className="coopeRative" name="Technologyofqualification" id="Technologyofqualification">
                        <div className="headings"><h2>合作企业</h2></div>
                        <div className="cooperativelist">
                            <Enterprise arr={this.state.arr} />
                        </div>
                    </div>
                    <div className="honor">
                        <div className="headings"><h2 style={{ color: 'white' }}>炫眼荣誉</h2></div>
                        <img src='/assets/国高.png' alt="" />
                        <img src='/assets/杭高.png' alt="" />
                        <img src='/assets/雏鹰.png' alt="" />
                    </div>

                </div>
                {/* 企业荣誉 */}
                <img src='/assets/企业荣誉1.png' alt="" className="honorimg">
                </img>
                {/* 企业风采 */}
                <div className="Elegant">
                    <div className="headings"><h2>企业风采</h2></div>
                    <div className="Elegantimg">
                        <Itemimg itemimg={this.state.itemimg} />
                    </div>
                </div>
                {/* 联系我们 */}
                <div className="us">
                    <div className="Amaodian" name="Cooperationtonegotiate" id="Cooperationtonegotiate"></div>
                    <div className="headings"><h2>联系我们</h2></div>
                    <h3>企业邮箱</h3>
                    <p>{this.state.email}</p>
                    <h3>企业地址</h3>
                    <p>{this.state.address}</p>
                    <h3>联系电话</h3>
                    <p>{this.state.phone}</p>
                </div>
            </div>
        )
    }
}
