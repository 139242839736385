import React, { Component } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom';
import ScrollToTopM from './HeaderM/ScrollToTopM'
import HeaderM from './HeaderM'
import FooterM from './FooterM'
import HomeM from '../HomeM'
import ProductM from '../ProductM'
import CaseshowsM from '../CaseshowsM'
import AboutM from '../AboutM'
import AboutM1 from '../AboutM1'
import SolutionM from '../SolutionM'
import LargegM from '../StarcaseM/LargegM'
import FoursiminghuM from '../StarcaseM/FoursiminghuM'
import WisdomparkM from '../StarcaseM/WisdomparkM'
import NanxuntownM from '../StarcaseM/NanxuntownM'
import PartnerM from '../Articlem/PartnerM'
import PartnerM4 from '../Articlem/PartnerM4'
import PartnerM2 from '../Articlem/PartnerM2'
import PartnerM3 from '../Articlem/PartnerM3'
import PubSub from 'pubsub-js'
import './index.scss'

export default class BasisMobile extends Component {
    state = {
        name: 'shouji',
    }
    scrollToAnchor(anchorName) {
        if (anchorName) {
            setTimeout(() => {
                let anchorElement = document.getElementById(anchorName);
                // 如果对应id的锚点存在，就跳转到锚点
                if (anchorElement) { anchorElement.scrollIntoView({ block: 'start' }); }
            }, 800);
            // 找到锚点
        }
    }
    componentDidMount() {
        PubSub.subscribe('shouji', (_, data) => {
            // console.log(data);
            this.setState(data);      
        })
    }
    componentWillUnmount() {
        this.setState = () => false;
    }
    render() {
        this.scrollToAnchor(this.state.name)
        return (
            <div>
                <HeaderM />
                <ScrollToTopM>
                    <Switch>
                        <Route path="/TheHomeM" component={HomeM} />
                        <Route path="/TheSolutionM" component={SolutionM} />
                        <Route path="/TheProductM" component={ProductM} />
                        <Route path="/TheAboutM" component={AboutM} />
                        <Route path="/TheAboutM1" component={AboutM1} />
                        <Route path="/LargegamountofmicrobrainM" component={LargegM} />
                        <Route path="/ThePartnerM" component={PartnerM} />
                        <Route path="/ThePartnerM2" component={PartnerM2} />
                        <Route path="/ThePartnerM3" component={PartnerM3} />
                        <Route path="/ThePartnerM4" component={PartnerM4} />
                        <Route path="/ThecaseshowsM" component={CaseshowsM} />
                        <Route path="/FoursiminghuM" component={FoursiminghuM} />
                        <Route path="/WisdomparkM" component={WisdomparkM} />
                        <Route path="/NanxuntownM" component={NanxuntownM} />
                        <Redirect to="/TheHomeM" />
                    </Switch>
                </ScrollToTopM>
                <FooterM />
            </div>

        )
    }
}
