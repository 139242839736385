import React, { Component } from 'react'
import { Button } from 'antd';
// import solutionimg from '@assets/智慧园区大.png'
import { Link } from 'react-router-dom';
import './index.css'

export default class Solution1 extends Component {
    state = {
        p: '面向园区安防管理、实现园区综合安防展示的可视化决策辅助平台。平台以三维场景为载体，将园区安防设备运行核心系统的各项关键数据进行综合展示、挖掘分析、应用等，支持从园区安防管理等多个维度进行日常运行监测、管理、预判，以及突发事件的应急指挥调度管理，为园区安防管理提供多维一体的智能管理平台，为园区管理者提高园区安防管理效率，提供数据决策支撑。'
    }
    render() {
        let userMessage;
        if (this.props.loggedIn) {
            userMessage = (
                ''
            )
        } else {
            userMessage = (
                <Link to='/Wisdompark'>
                    <Button ghost className="solutionbutton">立即查看</Button>
                </Link>
            )
        }
        return (
            <div>
                <div className="solutioncasetext">
                    <div className="solutioncasetextp">
                        <p>
                            {this.state.p}
                        </p>
                    </div>
                    {userMessage}
                </div>
                <div className="solutionimgdivs">
                    <div className="solutionimgdiv">
                        <img src="/assets/智慧园区大.png" alt="" className="solutionimg" />
                    </div>
                </div>
            </div>

        )
    }
}
