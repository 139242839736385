import React, { Component } from 'react'
import { Breadcrumb } from 'antd';
import PubSub from 'pubsub-js'

export default class Largeg extends Component {
    state = {
        largetitle: '智慧园区安防综管平台',
    }
    search = () => {
        PubSub.publish('shuju', {
            changemenu1: false,
            changemenu2: false,
            changemenu3: true,
            changemenu4: false,
            changemenu5: false,
            changemenu6: false,
        })
    }
    render() {
        return (
            <div className="largebody">
                <Breadcrumb className="Bread" separator='>'>
                    <Breadcrumb.Item href="https://xuanyantech.com/#/TheHome">
                        首页
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="https://xuanyantech.com/#/Thecaseshows" onClick={this.search}>明星案例推荐</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">{this.state.largetitle}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="largetitle">
                    <h1>{this.state.largetitle}</h1>
                </div>
                <div className="largetxt">
                    <p> 安防管控：面向园区安防管理、实现园区综合安防展示的可视化决策辅助平台。平台以三维场景为载体，将园区安防设备运行核心系统的各项关键数据进行综合展示、挖掘分析、应用等，支持从园区安防管理等多个维度进行日常运行监测、管理、预判，以及突发事件的应急指挥调度管理，为园区安防管理提供多维一体的智能管理平台，为园区管理者提高园区安防管理效率，提供数据决策支撑。</p>
                    <p> 
                        核心模块：1、校园数字孪生场景  2、预警中心 3、监控可视化平台 4、校园车行管理 5、人行管理    6、消防可视化管理  7、校园安防综合概况展示 8、移动端小程序数据采集平台
                    </p>
                    <img src='/assets/承载园区.png' alt="" />
                    <img src='/assets/承载园区1.png' alt="" />
                </div>
            </div>
        )
    }
}
