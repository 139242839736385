import React, { Component } from 'react'
import { Accordion, List } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import Jjfa from './Jjfa'
import PubSub from 'pubsub-js'
import './index.scss'
import { Link } from 'react-router-dom';

export default class HeaderM extends Component {
    state = {
        match: true,
    }
    // state = {
    //     caidan: true
    // }
    // onChange = () => {
    //     this.setState({ caidan: !this.state.caidan })
    // }
    onChange = () => {
        this.setState({
            match: !this.state.match,
        })
    }
    search2 = () => {
        PubSub.publish('shouji', { name: 'shouji' })
    }
    render() {
        // let caidan;
        // if (this.state.caidan) {
        //     caidan = (
        //         <img src={require('@assets/菜单.png').default} alt="" className="caidan"  onClick={this.onChange}/>
        //     )
        // } else {
        //     caidan = (<img src={require('@assets/叉号.png').default} alt="" className="chahao" onClick={this.onChange}/>)
        // }
        return (
            <div className="HeaderM" >
                <Link to="/TheHomeM">
                    <img src='/assets/logom.png' alt="" />
                </Link>
                {/* {caidan} */}
                {/* 头部样式 */}
                <Accordion defaultActiveKey="1" className="my-accordion" onChange={this.onChange} >
                    <Accordion.Panel header="" >
                        <List className="my-list">
                            <Link to="/TheHomeM" onClick={this.expand}>
                                <List.Item style={{ marginTop: '50pt' }}>首页</List.Item>
                            </Link>
                            <List.Item>
                                {/* 解决方案 */}
                                <Jjfa />
                            </List.Item>
                            <Link to="/TheCaseshowsM">
                                <List.Item>案例展示</List.Item>
                            </Link>
                            <Link to="/TheProductM">
                                <List.Item>产品技术</List.Item>
                            </Link>
                            <Link to="/TheAboutM">
                                <List.Item>关于我们</List.Item>
                            </Link>
                            {/* <Link to="/TheAboutM" onClick={this.search2}> */}
                            <List.Item>
                                <a href="/#/TheAboutM1" onClick={this.search2} style={{ color: '#2468F2' }}>
                                    合作洽谈
                                </a>
                            </List.Item>
                            {/* </Link> */}
                        </List>
                    </Accordion.Panel>
                </Accordion>

            </div>
        )
    }
}
