import React, { Component } from 'react'
import './index.scss'

export default class FoursiminghuM extends Component {
    render() {
        return (
            <div className="largegmbody">
                <div className="largegmtop">
                    <div className="backm" onClick={() => this.props.history.goBack()}>
                        <img src={require('@assets/路径.png').default} alt="" />
                        <span>返回</span>
                    </div>
                </div>
                <h1>四明湖水库智慧水利</h1>
                <h4>助力园区高标准建设、高质量管理、高效化运营</h4>
                <p>
                    智慧水利：为管理和决策者掌握水库环境资源的布局分布、实时动态变化、环境监管的数据联动、应急事故的指挥调度等提供科学的依据。</p>
                <p>核心模块：周边水位线监测报警、水流量统计分析监测、闸位计统计分析监测、蒸发站统计分析监测、墒情站统计分析监测、雨量数据计分析监测、大坝渗流观测统计分析、坝面变形观测统计分析、水库安防监控 硕士培养高校第一名，2020年列软科中国大学排名硕士点高校第一。</p>
                <img src='/assets/四明湖.png' alt="" />
                <img src='/assets/四明湖1.png' alt="" />
            </div>
        )
    }
}
