import React, { Component } from 'react'
import Solutiondiv from './Solutiondiv'
import Platform from './Platform'
import Solutioncase1 from './Solutioncase1'
import Solutioncase2 from './Solutioncase2'
import Solutioncase3 from './Solutioncase3'
import Solutioncase4 from './Solutioncase4'
import Yycj from './Yycj'
// import Tscj from '@assets/'
// import VRll from '@assets/'
// import Ssjk from '@assets/'
// import Sjzh from '@assets/'
import PubSub from 'pubsub-js'
import './index.css'

export default class Solution extends Component {
    state = {
        licss1: true,
        licss2: false,
        Solutioncases: '1',
        Solutiondiv1: [{
            sdh2: '产品性能',
            sdp: '承载能力：平台可承载省级及以上精细模型数据体量',
            sdpbr1: '加载能力：充分保障系统流程性',
            sdpbr2: '分布式部署：支持分布式部署，可满足多个应用系统的同时调用',
            sdpbr3: '物联网接入能力：支持主流物联网设备接入功能',
            sdpbr4: '移动端兼容性：支持IOS、android系统操控',
            sdpbr5: '远程服务能力：具备远程数据更新、远程维护，降低维护成本',
            sdpbr6: '终端硬件低要求：对应用端硬件性能要求低，中端以上硬件终端即可支持',
            imgs: '/assets/特色场景.png'
        },
        {
            sdh2: '数字孪生场景精度',
            sdp: '在数字孪生的构建中，数据是基础，模型是核心，平台是载体。',
            sdpbr1: '在用户交互层面，打破空间和时间的约束。',
            sdpbr2: '空间尺度中，实现任意拆解、微观宏观自由切换、多视角观察。',
            sdpbr3: '孪生的精度主要依赖于数据的质量，采集到的数据越完整、颗粒度越小，数字孪生场景的刻画就越精细。',
            imgs: '/assets/数据整合.png'
        },
        {
            sdh2: '引擎功能应用',
            sdp: '地图精度按照国家 1:500 制图标准要求',
            sdpbr1: '场景区域—建筑—楼层—房间—物体，都可实现精细的可视化孪生管理',
            sdpbr2: '流畅的模型切换，视角转换',
            imgs: '/assets/实时监控.png'
        },
        {
            sdh2: '综合应用展示',
            sdp: '通过3DGIS可视化管理，对设备的基本信息、来源用途、运检情况、运行状态、安全性以及负责人的调度等进行高效管理。',
            sdpbr1: '全角色：轻松应对各类人员数据分析需求',
            sdpbr2: '集成的页面展示，提供企业效率',
            imgs: '/assets/VR浏览.png'
        }
        ]
    }
    handlesolu1 = () => {
        this.setState({
            Solutioncases: '1',
            licss1: true,
            licss2: false,
            licss3: false,
            licss4: false,
        })
    }
    handlesolu2 = () => {
        this.setState({
            Solutioncases: '2',
            licss2: true,
            licss1: false,
            licss3: false,
            licss4: false,
        })
    }
    handlesolu3 = () => {
        this.setState({
            Solutioncases: '3',
            licss2: false,
            licss1: false,
            licss3: true,
            licss4: false,
        })
    }
    handlesolu4 = () => {
        this.setState({
            Solutioncases: '4',
            licss2: false,
            licss1: false,
            licss3: false,
            licss4: true,
        })
    }
    componentDidMount() {
        this.address()
    }
    address = () => {
        PubSub.publish('address', {
            address: this.props.location.pathname
        });
    }
    render() {
        return (
            <div className="Solution">
                <div className="visualization">
                </div>
                <div className="visualizationup">
                </div>
                <div className="visualizationtitle">
                    <h1>三维数字可视化</h1>
                    <p className="Solutionp">炫眼科技3D数据可视化引擎，为用户提供构建数字孪生应用的强有力软件工具包</p>
                </div>
                {/* 应用场景 */}
                <Yycj />
                {/* 平台架构 */}
                <Platform />
                {/* 技术优势 */}
                <div className="Application" id="Digitalvisualization">
                    <div className="Applicationtitle"><h2>技术优势</h2></div>
                    <div className="advantages1"><Solutiondiv Solutiondiv1={this.state.Solutiondiv1} /></div>
                </div>
                <div className="Application" style={{ height: '560px' }}>
                    <div className="Applicationtitle"><h2>相关案例</h2></div>
                    <div className="applicationlist">
                        <div className="Applicationglistul">
                            <ul>
                                <li onClick={this.handlesolu1} className={this.state.licss1 ? 'Applicationglistulli' : 'Applicationglistullihover'}><p className={this.state.licss1 ? 'licssg' : 'licss'} > 中国计量大学 - 量大微脑</p></li>
                                <li onClick={this.handlesolu2} className={this.state.licss2 ? 'Applicationglistulli' : 'Applicationglistullihover'}><p className={this.state.licss2 ? 'licssg' : 'licss'} >四明湖水库智慧水利</p></li>
                                <li onClick={this.handlesolu3} className={this.state.licss3 ? 'Applicationglistulli' : 'Applicationglistullihover'}><p className={this.state.licss3 ? 'licssg' : 'licss'} >智慧园区安防综管平台</p></li>
                                <li onClick={this.handlesolu4} className={this.state.licss4 ? 'Applicationglistulli' : 'Applicationglistullihover'}><p className={this.state.licss4 ? 'licssg' : 'licss'} >南浔古镇数字沙盘</p></li>
                            </ul>
                        </div>
                    </div>
                    <div className="solutioncase">
                        {(() => {
                            switch (this.state.Solutioncases) {
                                case "1": return <Solutioncase1 />;
                                case "2": return <Solutioncase2 />;
                                case "3": return <Solutioncase3 />;
                                case "4": return <Solutioncase4 />;
                                default: return null;
                            }
                        }
                        )()}
                    </div>
                </div>
            </div>
        )
    }
}
