import React, { Component } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import './index.scss'


import SwiperCore, {
    Navigation
} from 'swiper';
SwiperCore.use([Navigation]);
export default class CultureM extends Component {
    render() {
        return (
            <div className="culture">
                <div className="culturemimg">
                    <img src='/assets/办公环境.png' alt="" />
                </div>
                <Swiper navigation={true} className="mySwiper" style={{ width: '100%' }}>
                    <SwiperSlide style={{height:'130px'}}>
                        <div style={{ height: '100px' }}>
                            <h1>诚信</h1>
                            <p>· 对外诚实守信</p><br/>
                            <p>· 对内说到做到</p><br/>
                            <p>· 工作中敢于提出问题</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{height:'130px'}}>
                        <div style={{ height: '100px' }}>
                            <h1>诚信</h1>
                            <p>· 专注做到极致</p><br/>
                            <p>· 对于品质专注</p><br/>
                            <p>· 不断的提高要求</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{height:'130px'}}>
                        <div style={{ height: '100px' }}>
                            <h1>高效</h1>
                            <p>· 高效是我们办事的准则</p><br/>
                            <p>· 做必要的沟通</p><br/>
                            <p>· 最快的速度响应用户</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{height:'130px'}}>
                        <div style={{ height: '100px' }}>
                            <h1>创新</h1>
                            <p>· 始终像创业初期保持创新</p><br/>
                            <p>· 接受新事物，走出舒适圈</p><br/>
                            <p>· 不怕困难，坚定脚步</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        )
    }
}
