import React, { Component, StrictMode } from 'react'
import { Carousel, Button } from 'antd';
import { Player, BigPlayButton, ControlBar } from 'video-react';
import { Link } from 'react-router-dom'
import "../../../node_modules/video-react/dist/video-react.css";
// import Basketball from 'https://xuanyantech.com/assets/官网视频.mp4'
import PubSub from 'pubsub-js'
import Many from './Many'
import Dynamic from './Dynamic'
import Star from './Star'
import './index.css'

export default class Home extends Component {
    state = {
        csschange1: false,
        csschange2: true,
        csschange3: true,
        csschange4: true,
        csschange5: true,
        home3dli1: '·IOT模块接入',
        home3dli2: '·硬件交互控制',
        home3dli3: '·时空场景引用',
        home3dli4: '·原有系统业务接入',
        home3dli5: '·多样化图表展示',
        home3dli6: '·云渲染服务',
        homedigital1: '·LOD多精度选择',
        homedigital2: '·楼宇场景还原',
        homedigital3: '·高程地形还原',
        homedigital4: '·绿化场景还原',
        homedigital5: '·管网管道还原',
        homedigital6: '·交通路线还原',
        homeproduct1: '·大场景空间表达',
        homeproduct2: '·室内空间表达',
        homeproduct3: '·资产可视化管理',
        homeproduct4: '·安防可视化管理',
        homeproduct5: '·多平台数据打通',
        homeproduct6: '·事件复盘演练',
        homeperformance1: '·市级模型体量承载',
        homeperformance2: '·远程更新与维护',
        homeperformance3: '·高安全性',
        homeperformance4: '·多平台兼容',
        Platformaim1: '·历史再现/归纳总结/隐患剖析',
        Platformaim2: '·远程物联/动态仿真/数字管控',
        Platformaim3: '·及时预防/态势模拟/应急预案',
        isWarning: '',
        loop: false
    }
    handleChange1 = () => {
        this.setState({
            csschange1: false,
            csschange2: true,
            csschange3: true,
            csschange4: true,
            csschange5: true,
        })
    }
    handleChange2 = () => {
        this.setState({
            csschange1: true,
            csschange2: false,
            csschange3: true,
            csschange4: true,
            csschange5: true,
        })
    }
    handleChange3 = () => {
        this.setState({
            csschange1: true,
            csschange2: true,
            csschange3: false,
            csschange4: true,
            csschange5: true,
        })
    }
    handleChange4 = () => {
        this.setState({
            csschange1: true,
            csschange2: true,
            csschange3: true,
            csschange4: false,
            csschange5: true,
        })
    }
    handleChange5 = () => {
        this.setState({
            csschange1: true,
            csschange2: true,
            csschange3: true,
            csschange4: true,
            csschange5: false,
        })
    }

    // 轮播图调用时间

    componentDidMount() {
        this.showSaveCover();
        this.address()
    }
    address=()=>{
        PubSub.publish('address',{
            address:this.props.location.pathname
        });
    }
    showSaveCover() {
        this.setState({
            isWarning: 12000
        });
        this.hideSaveCover();
    }

    hideSaveCover() {
        var self = this;
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            self.setState({
                isWarning: 5000
            });
        }, 7000);
    }
    vplay = () => {
        this.setState({ loop: true })
    }
    render() {
        const settings = {
            autoplaySpeed: this.state.isWarning,
            draggable: true,
            afterChange: this.vplay
        }
        return (
            <div>
                {/* 轮播图 */}
                <Carousel {...settings} autoplay>
                    <div className="xuanyan">
                        <Player
                            playsInline
                            aspectRatio='auto'
                            muted={true}
                            autoPlay
                            // poster='@assets/logoblue.png'
                            src="/assets/官网视频.mp4"
                            object-fit='fill'
                            className="homevideo"
                            loop={this.state.loop}
                        >
                            <BigPlayButton position="center" disabled />
                            <ControlBar autoHide={false} disableCompletely={true} />
                        </Player>
                    </div>

                    <div className="xuanyan1">
                        {/* <img src={require('../../assets/轮播图2.png').default} alt="" /> */}
                        <Link to="/TheSolution">
                            <Button ghost className="lbbutton">
                                立即查看
                            </Button>
                        </Link>
                    </div>
                    <div className="xuanyan2">
                        {/* <img src={require('../../assets/轮播图3.png').default} alt="" /> */}
                        <Link to="/TheSolution">
                            <Button ghost className="lbbutton">
                                立即查看
                            </Button>
                        </Link>
                    </div>
                </Carousel>,
                {/* 三维引擎模块 */}
                <div className="engine">
                    {/* 字体 */}
                    <div className="engineTitle"><h1 style={{ fontSize: 46 }}>三维数字孪生引擎</h1><p>致力于成为最优质的GIS平台供应商</p></div>
                    {/* 四个架构 */}
                    <div className="architecture">
                        <div className={this.state.csschange1 ? 'box' : 'boxg'} style={{ border: '0' }} onMouseMove={this.handleChange1}>
                            <div className={this.state.csschange1 ? 'box1' : 'boxg1'}><h2>3D数字孪生平台架构</h2></div>
                            <div className={this.state.csschange1 ? 'box2' : 'boxg2'}>
                                <div className="over">
                                    {this.state.home3dli1}
                                </div>
                                <div className="over">
                                    {this.state.home3dli2}
                                </div>
                                <div className="over">
                                    {this.state.home3dli3}
                                </div>
                            </div>
                            <div className={this.state.csschange1 ? 'Box2' : 'Boxg2'}>
                                <div className="over">
                                    {this.state.home3dli4}
                                </div>
                                <div className="over">
                                    {this.state.home3dli5}
                                </div>
                                <div className="over">
                                    {this.state.home3dli6}
                                </div>
                            </div>
                            <div className={this.state.csschange1 ? 'box3' : 'boxg3'}><div className={this.state.csschange1 ? 'imgg1' : 'img1'}></div></div>
                        </div>
                        {/* 数字场景精度 */}
                        <div className={this.state.csschange2 ? 'box' : 'boxg'} onMouseMove={this.handleChange2}>
                            <div className={this.state.csschange2 ? 'box1' : 'boxg1'}  ><h2>数字孪生场景精度</h2></div>
                            <div className={this.state.csschange2 ? 'box2' : 'boxg2'}  >
                                <div className="over">
                                    {this.state.homedigital1}
                                </div>
                                <div className="over">
                                    {this.state.homedigital2}
                                </div>
                                <div className="over">
                                    {this.state.homedigital3}
                                </div>
                            </div>
                            <div className={this.state.csschange2 ? 'Box2' : 'Boxg2'}>
                                <div className="over">
                                    {this.state.homedigital4}
                                </div>
                                <div className="over">
                                    {this.state.homedigital5}
                                </div>
                                <div className="over">
                                    {this.state.homedigital6}
                                </div>
                            </div>
                            <div className={this.state.csschange2 ? 'box3' : 'boxg3'}  ><div className={this.state.csschange2 ? 'img2' : 'imgg2'}></div></div>
                        </div>
                        {/* 产品精度 */}
                        <div className={this.state.csschange3 ? 'box' : 'boxg'} onMouseMove={this.handleChange3}>
                            <div className={this.state.csschange3 ? 'box1' : 'boxg1'}  ><h2>产品精度</h2></div>
                            <div className={this.state.csschange3 ? 'box2' : 'boxg2'}  >
                                <div className="over">
                                    {this.state.homeproduct1}
                                </div>
                                <div className="over">
                                    {this.state.homeproduct2}
                                </div>
                                <div className="over">
                                    {this.state.homeproduct3}
                                </div>
                            </div>
                            <div className={this.state.csschange3 ? 'Box2' : 'Boxg2'}>
                                <div className="over">
                                    {this.state.homeproduct4}
                                </div>
                                <div className="over">
                                    {this.state.homeproduct5}
                                </div>
                                <div className="over">
                                    {this.state.homeproduct6}
                                </div>
                            </div>
                            <div className={this.state.csschange3 ? 'box3' : 'boxg3'}  ><div className={this.state.csschange3 ? 'img3' : 'imgg3'}></div></div>
                        </div>
                        {/* 产品性能 */}
                        <div className={this.state.csschange4 ? 'box' : 'boxg'} onMouseMove={this.handleChange4}>
                            <div className={this.state.csschange4 ? 'box1' : 'boxg1'}  ><h2>产品性能</h2></div>
                            <div className={this.state.csschange4 ? 'box2' : 'boxg2'}  >
                                <div className="over">
                                    {this.state.homeperformance1}
                                </div>
                                <div className="over">
                                    {this.state.homeperformance2}
                                </div>
                            </div>
                            <div className={this.state.csschange4 ? 'Box2' : 'Boxg2'}>
                                <div className="over">
                                    {this.state.homeperformance3}
                                </div>
                                <div className="over">
                                    {this.state.homeperformance4}
                                </div>
                            </div>
                            <div className={this.state.csschange4 ? 'box3' : 'boxg3'}  ><div className={this.state.csschange4 ? 'img4' : 'imgg4'}></div></div>
                        </div>
                        {/* 平台宗旨 */}
                        <div className={this.state.csschange5 ? 'box' : 'boxg'} onMouseMove={this.handleChange5}>
                            <div className={this.state.csschange5 ? 'box1' : 'boxg1'}  ><h2>平台宗旨</h2></div>
                            <div className={this.state.csschange5 ? 'box8' : 'box9'}  >
                                <p>{this.state.Platformaim1}</p>
                                <p>{this.state.Platformaim2}</p>
                                <p>{this.state.Platformaim3}</p>
                            </div>
                            <div className={this.state.csschange5 ? 'box3' : 'boxg3'}  ><div className={this.state.csschange5 ? 'img5' : 'imgg5'}></div></div>
                        </div>
                    </div>
                    {/* 明星案例推荐 */}
                    <div className="fttitle"><h1 style={{ fontSize: 46 }}>明星案例推荐</h1></div>
                </div>
                <div className="star">
                    <Star />
                </div>
                {/* 三维引擎背景块的结束，纵深精耕开始 */}
                <Many />
                {/* 企业动态 */}
                <h1 className="qydt" style={{ fontSize: 46 }}>行业动态</h1>
                <StrictMode>
                    <Dynamic />
                </StrictMode>
            </div>
        )
    }
}