import React, { Component } from 'react'
import Solutionswiper from './Solutionswiper'
import CaseM from './CaseM'
import ImageDemo from './ImageDemo'
// import tscj from 
// import ssjk from 
// import sjzh from 
// import VR from 
import AdvantagesM from './AdvantagesM'
import './index.scss'

export default class SolutionM extends Component {
    state = {
        amplification: true,
        jsys: [
            {
                img: '/assets/特色场景.png',
                title: '特色场景',
                p: '轻松应对各类人员数据分析需求新一代数据可视化产品',
                right: '10'
            },
            {
                img: '/assets/实时监控.png',
                title: '实时监控',
                p: '在数字孪生的构建中，数据是基础，模型是核心，平台是载体。'
            },
            {
                img: '/assets/数据整合.png',
                title: '数据整合',
                p: '场景区域—建筑—楼层—房间—物体，都可实现精细的可视化孪生管理',
                right: '10'
            },
            {
                img: '/assets/VR浏览.png',
                title: 'VR浏览',
                p: '轻松应对各类人员数据分析需求集成的页面展示，提供企业效率'
            },
        ]
    }
    render() {
        return (
            <div>
                <div className="solutionmback">
                </div>
                <h1 className="solutionmbacktitle">数据可视化</h1>
                <div className="solutionmbody">
                    <div className="solutionmtitle"><h1>应用场景</h1></div>
                    <Solutionswiper />
                    {/* 平台结构 */}
                    <div className="solutionmtitle"><h1>平台框架</h1></div>
                    <ImageDemo />
                    {/* <img src='https://www.xuanyantech.com/assets/平台架构.png' alt="" className='ptjgm' /> */}
                    {/* 技术优势 */}
                    <div className="solutionmtitle" style={{ marginBottom: '40px' }}><h1>技术优势</h1></div>
                    <div className="advantagesm">
                        <AdvantagesM jsys={this.state.jsys} />
                    </div>
                    {/* 相关案例 */}
                    <div className="solutionmtitle"><h1>相关案例</h1></div>
                    <CaseM />
                </div>
            </div>
        )
    }
}
