import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import { Affix } from 'antd';
import ScrollToTop from './Header/ScrollToTop'
import Home from '../Home'
import Solution from '../Solution'
import Product from '../Product'
import About from '../About'
import Please from '../Please'
import Largeg from '../Starcase/Largeg'
import Partner from '../Partner'
import Partner2 from '../Partner2'
import Partner3 from '../Partner3'
import Partner4 from '../Partner4'
import Header from './Header'
import Footer from './Footer'
import SimingLakeReservoir from '../Starcase/Foursiminghu'
import Wisdompark from '../Starcase/Wisdompark'
import Nanxuntown from '../Starcase/Nanxun town'
import Caseshows from '../Caseshows'
import PubSub from 'pubsub-js'
import './index.css'


export default class Basis extends Component {
    state = {
        name: 'Companyprofile'
    }

    scrollToAnchor(anchorName) {
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', }); }
        }
    }
    componentDidMount() {
        PubSub.subscribe('shuju', (_, data) => {
            this.setState(data);
        })
    }
    componentWillUnmount() {
        this.setState = () => false;
    }
    render() {
        this.scrollToAnchor(this.state.name)
        return (
            <div>
                <Affix offsetTop={0}>
                    <Header />
                </Affix>
                <ScrollToTop>
                    <Switch>
                        <Route path="/TheHome" component={Home} />
                        <Route path="/TheSolution" component={Solution} />
                        <Route path="/TheProduct" component={Product} />
                        <Route path="/TheAbout" component={About} />
                        <Route path="/Dopleaselookforwardto" component={Please} />
                        <Route path="/Largegamountofmicrobrain" component={Largeg} />
                        <Route path="/ThePartner" component={Partner} />
                        <Route path="/ThePartner2" component={Partner2} />
                        <Route path="/ThePartner3" component={Partner3} />
                        <Route path="/ThePartner4" component={Partner4} />
                        <Route path="/Thecaseshows" component={Caseshows} />
                        <Route path="/SimingLakeReservoir" component={SimingLakeReservoir} />
                        <Route path="/Wisdompark" component={Wisdompark} />
                        <Route path="/Nanxuntown" component={Nanxuntown} />
                        <Redirect to="/TheHome" />
                    </Switch>
                </ScrollToTop>
                <Footer />
            </div>
        )
    }
}
