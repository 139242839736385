import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import PubSub from 'pubsub-js'
import { Menu, Dropdown } from 'antd';
// import Home from '../../Home'
// import Solution from '../../Solution'
// import Product from '../../Product'
// import About from '../../About'
import './index.css'

const menu = (
    <Menu className="menublack">
        <Menu.Item className="menublacks" key="ldwn1">
            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                三维数据可视化
            </a>
        </Menu.Item>
        <Menu.Item className="menublacks" key="ldwn2">
            <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                虚拟仿真
            </a>
        </Menu.Item>
    </Menu>
);
export default class Header extends Component {
    state = {
        changemenu1: true,
        changemenu2: false,
        changemenu3: false,
        changemenu4: false,
        changemenu5: false,
    }
    componentWillUnmount() {
        this.setState = () => false;
    }
    search2 = () => {
        PubSub.publish('shuju', { name: 'Cooperationtonegotiate' })
    }
    changemenu1 = () => {
        this.setState({
            changemenu1: true,
            changemenu2: false,
            changemenu3: false,
            changemenu4: false,
            changemenu5: false,
            changemenu6: false,
        })
    }
    changemenu2 = () => {
        this.setState({
            changemenu1: false,
            changemenu2: true,
            changemenu3: false,
            changemenu4: false,
            changemenu5: false,
            changemenu6: false,
        })
    }
    changemenu3 = () => {
        this.setState({
            changemenu1: false,
            changemenu2: false,
            changemenu3: true,
            changemenu4: false,
            changemenu5: false,
            changemenu6: false,
        })
    }
    changemenu4 = () => {
        this.setState({
            changemenu1: false,
            changemenu2: false,
            changemenu3: false,
            changemenu4: true,
            changemenu5: false,
            changemenu6: false,
        })
    }
    changemenu5 = () => {
        PubSub.publish('shuju', { name: 'top' })
        this.setState({
            changemenu1: false,
            changemenu2: false,
            changemenu3: false,
            changemenu4: false,
            changemenu5: true,
            changemenu6: false,
        })
    }
    changemenus5 = () => {
        this.setState({
            changemenu1: false,
            changemenu2: false,
            changemenu3: false,
            changemenu4: false,
            changemenu5: true,
            changemenu6: false,
        })
    }
    changemenu6 = () => {
        this.setState({
            changemenu1: false,
            changemenu2: false,
            changemenu3: false,
            changemenu4: false,
            changemenu5: false,
            changemenu6: true,
        })
    }
    // 接受数据
    componentDidMount() {
        PubSub.subscribe('shuju', (_, data) => {
            this.setState(data);
        })
        PubSub.subscribe('address', (_, data) => {
            this.setState(data);   
            switch (data.address) {
                case "/TheHome":
                    this.setState({
                        changemenu1: true,
                        changemenu2: false,
                        changemenu3: false,
                        changemenu4: false,
                        changemenu5: false,
                        changemenu6: false,
                    }); break;
                case "/TheSolution":
                    this.setState({
                        changemenu1: false,
                        changemenu2: true,
                        changemenu3: false,
                        changemenu4: false,
                        changemenu5: false,
                        changemenu6: false,
                    }); break;
                case "/Thecaseshows":
                    this.setState({
                        changemenu1: false,
                        changemenu2: false,
                        changemenu3: true,
                        changemenu4: false,
                        changemenu5: false,
                        changemenu6: false,
                    }); break;
                case "/TheProduct":
                    this.setState({
                        changemenu1: false,
                        changemenu2: false,
                        changemenu3: false,
                        changemenu4: true,
                        changemenu5: false,
                        changemenu6: false,
                    }); break;
                case "/TheAbout":
                    this.setState({
                        changemenu1: false,
                        changemenu2: false,
                        changemenu3: false,
                        changemenu4: false,
                        changemenu5: true,
                        changemenu6: false,
                    }); break;
                default: return null;
            }
        })

    }
changeout=()=>{
    switch (this.state.address) {
        case "/TheHome":
            this.setState({
                changemenu1: true,
                changemenu2: false,
                changemenu3: false,
                changemenu4: false,
                changemenu5: false,
                changemenu6: false,
            }); break;
        case "/TheSolution":
            this.setState({
                changemenu1: false,
                changemenu2: true,
                changemenu3: false,
                changemenu4: false,
                changemenu5: false,
                changemenu6: false,
            }); break;
        case "/Thecaseshows":
            this.setState({
                changemenu1: false,
                changemenu2: false,
                changemenu3: true,
                changemenu4: false,
                changemenu5: false,
                changemenu6: false,
            }); break;
        case "/TheProduct":
            this.setState({
                changemenu1: false,
                changemenu2: false,
                changemenu3: false,
                changemenu4: true,
                changemenu5: false,
                changemenu6: false,
            }); break;
        case "/TheAbout":
            this.setState({
                changemenu1: false,
                changemenu2: false,
                changemenu3: false,
                changemenu4: false,
                changemenu5: true,
                changemenu6: false,
            }); break;
        default: return null;
    }
}
    render() {
        return (
            <div className="header" onMouseOut={this.changeout}>
                <NavLink to='/TheHome' activeClassName="active">
                    <div className="logo"></div>
                </NavLink>
                <div className="navigation">
                    <NavLink to='/TheHome' activeClassName="active">
                        <div className={this.state.changemenu1 ? 'menuhover' : 'menu'} onClick={this.changemenu1} onMouseMove={this.changemenu1}>
                            <span>首页</span>
                        </div>
                    </NavLink>
                    <NavLink to='/TheSolution' activeClassName="active">
                        <Dropdown overlay={menu} placement="bottomCenter">
                            <div className={this.state.changemenu2 ? 'menuhover' : 'menu'} onClick={this.changemenu2} onMouseMove={this.changemenu2}>
                                <span>解决方案</span>
                            </div>
                        </Dropdown>
                    </NavLink>
                    <NavLink to='/Thecaseshows' activeClassName="active">
                        <div className={this.state.changemenu3 ? 'menuhover' : 'menu'} onClick={this.changemenu3} onMouseMove={this.changemenu3}>
                            <span>案例展示</span>
                        </div>
                    </NavLink>
                    <NavLink to='/TheProduct' activeClassName="active">
                        <div className={this.state.changemenu4 ? 'menuhover' : 'menu'} onClick={this.changemenu4} onMouseMove={this.changemenu4}>
                            <span>产品技术</span>
                        </div>
                    </NavLink>
                    <NavLink to='/TheAbout' activeClassName="active">
                        <div className={this.state.changemenu5 ? 'menuhover' : 'menu'} onClick={this.changemenu5} onMouseMove={this.changemenus5}>
                            <span>关于我们</span>
                        </div>
                    </NavLink>
                    <div className={this.state.changemenu6 ? 'menuhover' : 'menu'} onClick={this.changemenu6} onMouseMove={this.changemenu6}>
                        <a href="/#/TheAbout" onClick={this.search2}><span>合作洽谈</span></a>
                    </div>
                </div>
            </div>
        )
    }
}
