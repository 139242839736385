import React, { Component } from 'react'
import { RightOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { Button, Affix } from 'antd';
import PubSub from 'pubsub-js'
import './index.css'


export default class Many extends Component {


    state = {
        div1: true,
        div2: false,
        div3: false,
        dic4: false,
        isWarning: false,
    }
    handlemany1 = () => {
        this.setState({
            div1: true,
            div2: false,
            div3: false,
            div4: false,
        })
    }
    handlemany2 = () => {
        this.setState({
            div1: false,
            div2: true,
            div3: false,
            div4: false,
        })
    }
    handlemany3 = () => {
        this.setState({
            div1: false,
            div2: false,
            div3: true,
            div4: false,
        })
    }
    handlemany4 = () => {
        this.setState({
            div1: false,
            div2: false,
            div3: false,
            div4: true,
        })
    }
    showSaveCover = () => {
        this.setState({
            isWarning: true
        });
        this.hideSaveCover();
    }

    hideSaveCover() {
        var self = this;
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            self.setState({
                isWarning: false
            });
        }, 2000);
    }
    search = () => {
        PubSub.publish('shuju', {
            changemenu1: false,
            changemenu2: true,
            changemenu3: false,
            changemenu4: false,
            changemenu5: false,
            changemenu6: false,
        })
    }
    render() {
        let loaddings;
        if (this.state.isWarning) {
            loaddings = (
                <Affix offsetTop={300} >
                    <div style={{ width: '100%', position: 'absolute' }}>
                        <div className="Hloading"><span style={{ fontSize: '18px' }} className="loadingspan">已经到头啦，敬请期待</span></div>
                    </div>
                </Affix>
            )
        } else {
            loaddings = ('')
        }
        let Div1;
        if (this.state.div1) {
            Div1 = (
                <span>
                    <h1 className="buttondc">三维数据可视化</h1>
                    <p className="buttondc"> 炫眼科技3D数据可视化引擎，为用户提供构建数字孪生应用的强有力软件工具包，基于webGL技术和云渲染技术整合推出3D数据可视化sdk,适合具有web开发能力的专业开发公司和综管平台建设需求用户，适用于数据驱动决策的应用场景。</p>
                    <h2 className="buttondc">应用范围</h2>
                    <h3 className="buttondc">智慧景区 | 智慧园区 |智慧社区 | 智慧湖泊 | 智慧高校 |智慧场管 | 智慧工厂|智慧工地 | 智慧医院</h3>
                    <NavLink to='/TheSolution' onClick={this.search}><Button ghost style={{ width: 200 }} className="buttondc">立即查看</Button></NavLink>
                </span>
            )
        } else {
            Div1 = (
                <span>
                    <img src='/assets/三角.png' alt="" />
                    <h1>数据可视化</h1>
                </span>
            )
        }
        let Div2;
        if (this.state.div2) {
            Div2 = (
                <span>
                    <h1 className="buttondc">虚拟仿真</h1>
                    <p className="buttondc"> 以高度逼真的视觉效果与数据化场景操控，给予用户沉浸式体验。企业以领先的虚拟仿真技术
                        实现多样化的虚拟现实行业解决方案，适配您的定制化需求
                    </p>
                    <h2 className="buttondc">应用范围</h2>
                    <h3 className="buttondc">实验教学 | 教育培训 | 工业仿真 </h3>
                    <NavLink to='/TheSolution' ><Button ghost style={{ width: 200 }} className="buttondc">立即查看</Button></NavLink>
                </span>
            )
        } else {
            Div2 = (
                <span>
                    <img src='/assets/人头1.png' alt="" />
                    <h1>虚拟仿真</h1>
                </span>
            )
        }
        let Div3;
        if (this.state.div3) {
            Div3 = (
                <span>
                    <h1 className="buttondc">AR、VR</h1>
                    <p className="buttondc"> 您提供行业需求、我们提供技术策划、共同打造AR新营销新创意。从创意到内容一站式完成，WebAR让AR无处不在，以H5的方式实现病毒式传播。</p>
                    <h2 className="buttondc">应用范围</h2>
                    <h3 className="buttondc">AR教育 | AR旅游 | AR文娱 | AR营销 </h3>
                    <NavLink to='/TheSolution' ><Button ghost style={{ width: 200 }} className="buttondc">立即查看</Button></NavLink>
                </span>
            )
        } else {
            Div3 = (
                <span>
                    <img src='/assets/人头2.png' alt="" />
                    <h1>AR、VR互动</h1>
                </span>
            )
        }
        let Div4;
        if (this.state.div4) {
            Div4 = (
                <span>
                    <h1 className="buttondc">其他方案</h1>
                    <p className="buttondc">为实现全域旅游构建智慧景区提供全方位服务，通过智能网络，对景区地理事物、自然资源、旅游者行为、景区工作人员行迹、景区基础设施和服务设施进行全面、透彻、及时的感知；对游客、景区工作人员实现可视化管理。</p>
                    <h2 className="buttondc">应用范围</h2>
                    <h3 className="buttondc">管控平台 | 小程序开发 | 公众号建设 | 语音导览导视系统 | 景区手绘与2.5d图 </h3>
                    <NavLink to='/TheSolution'><Button ghost style={{ width: 200 }} className="buttondc">立即查看</Button></NavLink>
                </span>
            )
        } else {
            Div4 = (
                <span>
                    <img src='/assets/人头3.png' alt="" />
                    <h1>其他方案</h1>
                </span>
            )
        }
        return (
            <div className="scenarios">
                {loaddings}
                <h1 className="scenariostitle" style={{ fontSize: 46 }}>纵深精耕 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;多场景解决方案</h1>
                <div className="scenariosdiv">
                    <div className={this.state.div1 ? 'manyimg1' : 'manyimgg1'}></div>
                    <div className={this.state.div2 ? 'manyimgg2' : 'manyimg2'}></div>
                    <div className={this.state.div3 ? 'manyimgg3' : 'manyimg3'}></div>
                    <div className={this.state.div4 ? 'manyimgg4' : 'manyimg4'}></div>
                    <div className="manyboxall">
                        <div className={this.state.div1 ? 'manybox1' : 'manyboxg1'} onMouseMove={this.handlemany1}>
                            {Div1}
                        </div>
                        <div className={this.state.div2 ? 'manyboxg2' : 'manybox2'} onMouseMove={this.handlemany2}>
                            {Div2}
                        </div>
                        <div className={this.state.div3 ? 'manyboxg3' : 'manybox3'} onMouseMove={this.handlemany3}>
                            {Div3}
                        </div>
                        <div className={this.state.div4 ? 'manyboxg4' : 'manybox4'} onMouseMove={this.handlemany4}>
                            {Div4}
                        </div>
                        <div className="manyimg5">
                            <div className="scenariosdiv5">
                                <RightOutlined style={{ fontSize: '70px', color: 'white' }} className="iconleft" onClick={this.showSaveCover} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
