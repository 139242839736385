import React, { Component } from 'react'
import { Breadcrumb } from 'antd';
import Othertext from './Othertext'
import './index.css'

export default class index extends Component {
    state = {
        dynamich1: '生态伙伴沙龙第四站・深圳',
        dynamich2: '生态伙伴沙龙第四站・深圳',
        dynamich3: '生态伙伴沙龙第四站・深圳',
        dynamich4: '生态伙伴沙龙第四站・深圳',
        dynamicp1: '据说SuperAPI又双叒更新啦！这次会带来哪些新功能，又有哪些高阶玩法？',
        dynamicp2: '2据说SuperAPI又双叒更新啦！这次会带来哪些新功能，又有哪些高阶玩法？',
        dynamicp3: '3据说SuperAPI又双叒更新啦！这次会带来哪些新功能，又有哪些高阶玩法？',
        dynamicp4: '4据说SuperAPI又双叒更新啦！这次会带来哪些新功能，又有哪些高阶玩法？',
    }
    render() {
        return (
            <div>
                <div className="partner" style={{height:'2050px'}}>
                    <div className="partnerBread">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item href="https://xuanyantech.com/#/TheHome">首页</Breadcrumb.Item>
                            <Breadcrumb.Item href="https://xuanyantech.com/#/ThePartner2">企业动态</Breadcrumb.Item>
                            <Breadcrumb.Item href="https://xuanyantech.com/#/ThePartner2">文章详情</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="partnertitle">
                        <h1 style={{ fontWeight: 'bold' }}>数据“活”了，腾讯位置服务推出移动端3D数据可视化图层</h1>
                        <p>发布日期 2021/08/18</p>
                    </div>
                    <div className="partnertext">
                        <img src='/assets/2000.jpg' style={{ width: '50%',marginBottom:'30px' }} alt="" />
                        <p>大数据能力在应用端逐渐普及,在保证数据完整性的同时,还要让这些数据能够快速被看懂、被吸收,才能发挥出这些数据的最大价值。近期,腾讯位置服务于业内率先发布地图SDK 3D数据可视化模块,基于OpenGL ES 3.0打造,继承Native引擎的渲染能力优势的可视化模块,可以充分发挥移动端GPU的并行计算能力,让移动端开发者不再受限于H5的性能短板,充分享受高性能海量数据渲染优势,将地理空间数据和地图完美结合。</p>
                        <p>
                        此前,腾讯位置服务面向Web端推出了数据可视化API,此次移动端可视化模块的发布,向“多端一体”、“一图胜千言”的空间数据可视化展示又迈进了一步。据了解,该可视化模块能向开发者提供多种数据可视化演示,包括全新的矢量热力图、蜂窝热力图、弧线图、轨迹图、散点图,覆盖多种数据类型,用于搭配不同的业务数据进行可视化展现。
                        </p>
                        <h3>3D立体动效展示,让数据更直观</h3>
                        <p>在每类图层中,腾讯位置服务都赋予了开发者丰富的属性配置,以打造炫酷多样的数据可视化效果。首先,可视化模块内的所有数据图层样式都支持3D效果展示,结合地图的俯仰视角变化,让用户的感受更加直观、立体。其次,辐射半径、聚合半径、数据区间、展示级别等参数的开放,让开发者即使面对多变的数据规模和分布,都有合理的呈现。最后,腾讯位置服务还提供了多种专业配色风格和对应的参数模板,开发者可以直接上手使用,为用户呈现全新的地图数据可视化视觉体验。</p>
                        <p>除了基础的静态绘制能力之外,腾讯位置服务的这套数据可视化模块还提供了图形的动效显示能力。例如在人口迁徙场景中,可以通过弧线的动态流向,来表现人口迁徙的方向。又比如在公园游客分布场景中,可以加入不同高度的动态拔起效果,来体现人流热力数据按时间变化的情况。还有散点图层的辐射动效,轨迹图的脉冲动效等等,这些丰富的动态效果让数据更加鲜活。
                        </p>
                        <h3>性能强化,细节体验自然流畅</h3>
                        <p>除了功能性的突破,腾讯位置服务也十分重视视觉体验,为了打造细腻逼真的视觉体验,在可视化图层的各种细节上进行精心打磨。
                        </p>
                        <p>腾讯位置服务在性能上也进行了优化升级,摒弃业内常用的瓦片方式呈现热力图效果,而是采用矢量方式,并且在多个层面都进行了效果升级,充分发挥GPU的并行计算能力,同时结合实例化渲染技术,大幅度提升了渲染性能,最高支持百万级热力点和万级弧线在移动端下的流畅操作,大大提升了使用过程中的流畅自然性。
                        </p>
                        <p>目前,腾讯位置服务移动端3D数据可视化模块1.0版本已经集成于腾讯地图SDK中,并可以在腾讯位置服务官网免费下载。未来腾讯位置服务会持续更多更优质的产品,为开发者们带来更实用、更便利的功能和服务。</p>
                        <p>来源：互联网</p>
                        <h3>注明：本公众号转载文章仅用于分享，不用于任何商业用途。如涉及版权问题，敬请后台联络授权或议定合作，我们会按照版权法规定第一时间为您妥善处理。</h3>
                    </div>
                    <div className="articles">
                        <h1>其他文章</h1>
                    </div>
                    <Othertext />
                </div>
            </div>

        )
    }
}


