// import React, { Component } from 'react'
// // import { Image } from 'antd';
// import './index.scss'

// export default class ImageDemo extends Component {
//     render() {
//         return (
//             <div>
//                 <a href="https://www.xuanyantech.com/assets/平台架构.png">
//                     <img src="https://www.xuanyantech.com/assets/平台架构.png" alt="" style={{ width: '100%' }} />
//                 </a>
//                 {/* < Image
//                     width='100%'
//                     // width={100}
//                     src="https://www.xuanyantech.com/assets/平台架构.png"
//                     style={{overflow:'auto'}}
//                 /> */}
//             </div>
//         )
//     }
// }


// import React, { Component } from 'react'
// import Viewer from 'react-viewer';

// function ImageDemo() {
//     const [visible, setVisible] = React.useState(false);
//     return (
//         <div>
//             <img src="https://www.xuanyantech.com/assets/平台架构.png" onClick={() => { setVisible(true); }} alt="" style={{width:'100%'}}></img>
//             <Viewer
//                 visible={visible}
//                 onClose={() => { setVisible(false); }}
//                 images={[{ src: "https://www.xuanyantech.com/assets/平台架构.png", alt: '' }]}
//             />
//         </div>
//     )
// }
// export default class ImageDemo1 extends Component {
//     render() {
//         return (
//             <div>
//                 <ImageDemo></ImageDemo>
//             </div>
//         )
//     }
// }


import React, { Component } from 'react'
import { PhotoProvider, PhotoConsumer } from 'react-photo-view';
import 'react-photo-view/dist/index.css';
import item1 from '@assets/平台架构.png'

export default class ImageDemo extends Component {
    render() {
        return (
            <div>
                <PhotoProvider>
                        <PhotoConsumer src={item1}>
                            <img src={item1} alt="" style={{width:'100%'}}/>
                        </PhotoConsumer>
                </PhotoProvider>
            </div>
        )
    }
}


