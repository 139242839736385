import React, { Component } from 'react'
import { Button } from 'antd';
import './index.scss'
import { Link } from 'react-router-dom';

export default class CaseM extends Component {
    state = {
        p1: '基于三维数字孪生校园通过对校园疫情常态化精准防控系统、安防管理软硬件系统、人车流软硬件系统、网上办事大厅软硬件系统、学校设备资产资源软硬件系统、学生公寓管理软硬件系统、校园实体办事大厅软硬件系统、微脑数据管理平台和空间数据及其属性信息进行采集,将校园空间地理信息和业务数据充分融合，实现校园各类大数据的可视化展示和决策管理。',
        p2: '为管理和决策者掌握水库环境资源的布局分布、实时动态变化、环境监管的数据联动、应急事故的指挥调度等提供科学的依据',
        p3: '面向园区安防管理、实现园区综合安防展示的可视化决策辅助平台。平台以三维场景为载体，将园区安防设备运行核心系统的各项关键数据进行综合展示、挖掘分析、应用等，支持从园区安防管理等多个维度进行日常运行监测、管理、预判，以及突发事件的应急指挥调度管理，为园区安防管理提供多维一体的智能管理平台，为园区管理者提高园区安防管理效率，提供数据决策支撑。',
        p4: '全面体现南浔古镇现状及未来规划蓝图，助力参观者景区信息导览，结合电子大屏与实体沙盘科技化的展示景区目前规划的理念与未来蓝图、三维导览、多元化介绍、多种待机模式助力游客游玩体验，通过多样数字化素诠释古镇底蕴。',
        CaseM1: true,
        CaseM2: false,
        CaseM3: false,
        CaseM4: false,
        CaseM5: false,
        key: '1'
    }
    changecase1 = () => {
        this.setState({
            CaseM1: true,
            CaseM2: false,
            CaseM3: false,
            CaseM4: false,
            CaseM5: false,
            key: '1'
        })
    }
    changecase2 = () => {
        this.setState({
            CaseM1: false,
            CaseM2: true,
            CaseM3: false,
            CaseM4: false,
            CaseM5: false,
            key: '2'
        })
    }
    changecase3 = () => {
        this.setState({
            CaseM1: false,
            CaseM2: false,
            CaseM3: true,
            CaseM4: false,
            CaseM5: false,
            key: '3'
        })
    }
    changecase4 = () => {
        this.setState({
            CaseM1: false,
            CaseM2: false,
            CaseM3: false,
            CaseM4: true,
            CaseM5: false,
            key: '4'
        })
    }
    changecase5 = () => {
        this.setState({
            CaseM1: false,
            CaseM2: false,
            CaseM3: false,
            CaseM4: false,
            CaseM5: true,
            key: '5'
        })
    }
    render() {
        return (
            <div className="CaseM">
                <div className="CaseMleft">
                    <ul>
                        <li className={this.state.CaseM1 ? 'caseli' : 'caselihover'} >
                            <p className={this.state.CaseM1 ? 'casep' : 'casephover'} onClick={this.changecase1}> 中国计量大学 - 量大微脑</p>
                        </li>
                        <li className={this.state.CaseM2 ? 'caseli' : 'caselihover'} >
                            <p className={this.state.CaseM2 ? 'casep' : 'casephover'} onClick={this.changecase2}>四明湖水库智慧水利</p>
                        </li>
                        <li className={this.state.CaseM3 ? 'caseli' : 'caselihover'}>
                            <p className={this.state.CaseM3 ? 'casep' : 'casephover'} onClick={this.changecase3}>智慧园区安防综管平台</p>
                        </li>
                        <li className={this.state.CaseM4 ? 'caseli' : 'caselihover'} >
                            <p className={this.state.CaseM4 ? 'casep' : 'casephover'} onClick={this.changecase4}>南浔古镇数字沙盘</p>
                        </li>
                        {/* <li className={this.state.CaseM5 ? 'caseli' : 'caselihover'} >
                            <p className={this.state.CaseM5 ? 'casep' : 'casephover'} onClick={this.changecase5}>电力集团<br />智慧园区管控</p>
                        </li> */}
                    </ul>
                </div>
                <div className="CaseMright">
                    {(() => {
                        switch (this.state.key) {
                            case "1": return <>
                                <img src={require('@assets/办事大厅概况手机.png').default} alt="" />
                                <p>{this.state.p1}</p>
                                <Link to="/LargegamountofmicrobrainM">
                                    <Button type="primary" shape='round' ghost className="CaseMbutton">
                                        立即查看
                                    </Button>
                                </Link>
                            </>;
                            case "2": return <>
                                <img src={require('@assets/四明湖手机.png').default} alt="" />
                                <p>{this.state.p2}</p>
                                <Link to="/FoursiminghuM">
                                    <Button type="primary" shape='round' ghost className="CaseMbutton">
                                        立即查看
                                    </Button>
                                </Link>
                            </>;
                            case "3": return <>
                                <img src={require('@assets/承载园区1手机.png').default} alt="" />
                                <p>{this.state.p3}</p>
                                <Link to="/WisdomparkM">
                                    <Button type="primary" shape='round' ghost className="CaseMbutton">
                                        立即查看
                                    </Button>
                                </Link>
                            </>;
                            case "4": return <>
                                <img src={require('@assets/南浔古镇手机.png').default} alt="" />
                                <p>{this.state.p4}</p>
                                <Link to="/NanxuntownM">
                                    <Button type="primary" shape='round' ghost className="CaseMbutton">
                                        立即查看
                                    </Button>
                                </Link>
                            </>;
                            default: return null;
                        }
                    }
                    )()}

                </div>
            </div>
        )
    }
}
