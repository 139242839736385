import React, { Component } from 'react'
import { Player, BigPlayButton, ControlBar } from 'video-react';
import mp4 from '@assets/计量微脑8.mp4'
import './index.scss'

export default class Video extends Component {
    state = {
        judge: false,
        suspended: ''
    }
    judge = () => {
        this.setState({
            judge: !this.state.judge
        })
    }
    render() {
        let videom;
        if (this.state.judge) {
            videom = (
                <  >
                    <Player src={mp4} autoPlay muted={true}>
                        <BigPlayButton position="center" disabled />
                        <ControlBar autoHide={false} disableCompletely={true} />
                    </Player>
                </ >
            )

        } else {
            videom = (
                <div>
                    <img src={require('@assets/办事大厅概况手机.png').default} alt="" onClick={this.judge} />
                    <div className="videom" onClick={this.judge}><img src={require("@assets/播放.png").default} style={{ width: '50px' }} alt="" /></div>
                </div>
            )
        }
        return (
            <div>
                {videom}
            </div >
        )
    }
}
