import React, { Component } from 'react'
import './index.css'

export default class Itemimg extends Component {
    render() {
        return (
            <div>
                {
                    this.props.itemimg.map(item => {
                        return (
                            <div className="aboutimg" key={item.imgs}>
                                <div className="aboutimgbig">
                                    <img src={item.imgs} alt="" className="itemimgs"></img>
                                </div>
                            </div >
                        )
                    })
                }
            </div>
        )
    }
}
