import React, { Component } from 'react'
import './index.css'

export default class Platform extends Component {
    render() {
        return (
            <div>
                            <div className="Application">
                            <div className="Applicationtitle"><h2>平台架构</h2></div>
                            <img src='/assets/平台架构.png' alt="" style={{marginLeft:'-50px',marginTop:'70px'}}/>
                            </div>
            </div>
        )
    }
}
