import React, { Component } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import './index.scss'

export default class StyleM extends Component {
    state = {
        isVisible1: true
    }
    render() {
        return (
            <div className="StyleM">
                <Swiper
                    effect={"cards"}
                    grabCursor={true} className="mySwiper"
                    style={{ width: '120%', marginLeft: '-10%' }}
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    spaceBetween={30}
                    loop
                    >
                    <SwiperSlide className="imgm1">
                        {({ isActive }) => (
                            <div className={isActive ? 'stylemimg1' : ''}>
                                <img src='/assets/6.jpg' alt="" />
                            </div>
                        )}
                    </SwiperSlide>
                    <SwiperSlide className="imgm1">
                        {({ isActive }) => (
                            <div className={isActive ? 'stylemimg1' : ''}>
                                <img src='/assets/4.jpg' alt="" />
                            </div>
                        )}
                    </SwiperSlide>
                    <SwiperSlide className="imgm1">
                        {({ isActive }) => (
                            <div className={isActive ? 'stylemimg1' : ''}>
                                <img src='/assets/2.jpg' alt="" />
                            </div>
                        )}
                    </SwiperSlide>
                    <SwiperSlide className="imgm1">
                        {({ isActive }) => (
                            <div className={isActive ? 'stylemimg1' : ''}>
                                <img src='/assets/3.jpg' alt="" />
                            </div>
                        )}
                    </SwiperSlide>
                    <SwiperSlide className="imgm1">
                        {({ isActive }) => (
                            <div className={isActive ? 'stylemimg1' : ''}>
                                <img src='/assets/1.jpg' alt="" />
                            </div>
                        )}
                    </SwiperSlide>
                    <SwiperSlide className="imgm1">
                        {({ isActive }) => (
                            <div className={isActive ? 'stylemimg1' : ''}>
                                <img src='/assets/5.jpg' alt="" />
                            </div>
                        )}
                    </SwiperSlide>
                </Swiper>
            </div>
        )
    }
}
